const infoWindowHandler = () => {
		const infoWindow = document.getElementById('infoWindow')
		const infoWindowTrigger = document.getElementById('infoWindowTrigger')
		let infoWindowOpenState = false

		if(!infoWindowTrigger || !infoWindow ){
			return false
		}
		const toggleInfoWindow = () => {
			infoWindowOpenState = !infoWindowOpenState

			infoWindowTrigger.innerHTML =  infoWindowOpenState ? 'Stäng' : 'Information'

			if(infoWindowOpenState) {
				infoWindowTrigger.classList.add('site-header__info-trigger--open')
				infoWindow.classList.add('site-header__info-window--open')
			} else {
				infoWindowTrigger.classList.remove('site-header__info-trigger--open')
				infoWindow.classList.remove('site-header__info-window--open')
			}
		}
		infoWindowTrigger.addEventListener('click', ( (e) => {
			toggleInfoWindow()

		}), false)

		document.addEventListener('click', ( (e) => {
			if(infoWindowOpenState) {
				const _target = infoWindow.contains(e.target);
				const _trigger = e.target.classList.contains('site-header__info-trigger');
				if(!_target && !_trigger) {
					toggleInfoWindow();
				}
			}
		}), false);
}

export default infoWindowHandler;
