// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.
import 'babel-polyfill'

import './lib/classlist.js';
import infoWindowHandler from './modules/infoWindowHandler';
import rangeSliderInfoWindowHandler from './modules/rangeSliderInfoWindowHandler';
import questionHandler from './modules/questionHandler';



document.addEventListener("DOMContentLoaded", () =>
{
	infoWindowHandler()
	rangeSliderInfoWindowHandler()
	questionHandler()
})
