const rangeSliderInfoWindowHandler = () => {
		const infoWindowTriggers = document.querySelectorAll('.form-input__label__info-window__trigger')
		let infoWindowOpenState = false

		if(!infoWindowTriggers){
			return false
		}
		// sorry about alll this...
		const toggleInfoWindow = (_trigger, _id, windowState) => {
				const infoWindow = document.getElementById(_id)
				_trigger.setAttribute('data-state', (windowState == 1 ? 0 : 1) )
				_trigger.innerHTML =  _trigger.getAttribute('data-state') == 1 ? 'Stäng' : 'Mer info'
				if(windowState == 0){
					_trigger.classList.add('form-input__label__info-window--open')
					infoWindow.classList.add('form-input__label__info-window--open')
				} else {
					_trigger.classList.remove('form-input__label__info-window--open')
					infoWindow.classList.remove('form-input__label__info-window--open')
				}

			}

			let windowState = false;
			for(let infoWindowTrigger of infoWindowTriggers) {

				infoWindowTrigger.addEventListener('click', ( (e) => {
					const currentItemId = infoWindowTrigger.getAttribute('data-id')
					const windowState = infoWindowTrigger.getAttribute('data-state')

						for(let _infoWindowTrigger of infoWindowTriggers) {
							const __id = _infoWindowTrigger.getAttribute('data-id')
							const __infoWindow = document.getElementById(__id)

							if(currentItemId !== __id) {
								_infoWindowTrigger.innerHTML = 'Mer info'
								_infoWindowTrigger.setAttribute('data-state', 0)
								_infoWindowTrigger.classList.remove('form-input__label__info-window--open')
								__infoWindow.classList.remove('form-input__label__info-window--open')

							} else {
								toggleInfoWindow(infoWindowTrigger, currentItemId, windowState)
							}
						}


				}), false)
			}

		document.addEventListener('click', ( (e) => {
				const openWindow = document.querySelector('.form-input__label__info-window.form-input__label__info-window--open')
				if(!openWindow){
					return false
				}
				const _target = openWindow.contains(e.target);
				const _trigger = e.target.classList.contains('form-input__label__info-window__trigger');
				if(!_target && !_trigger) {
					for(let infoWindowTrigger of infoWindowTriggers) {
						const _id = infoWindowTrigger.getAttribute('data-id')
						const infoWindow = document.getElementById(_id)

						infoWindowTrigger.innerHTML = 'Mer info'
						infoWindowTrigger.setAttribute('data-state', 0)
						infoWindowTrigger.classList.remove('form-input__label__info-window--open')
						infoWindow.classList.remove('form-input__label__info-window--open')
					}
				}

		}), false);
}

export default rangeSliderInfoWindowHandler;
